import { Card, Container, Tabs, Tab, Row, Col, Button, Modal, Accordion, Form, } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie';
import request from "./Api";
import Header from "./Header"
import { createHashHistory } from 'history';
import { BsPencilSquare, BsChevronDown, BsChevronUp, BsFillPlusCircleFill, BsArrowClockwise, BsFillCircleFill, BsLink } from "react-icons/bs";
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <Button variant="primary" size="sm" onClick={decoratedOnClick}>
            {children}
        </Button>

    );
}
function Admin({ updateGame }) {
    const [show, setShow] = useState(false);
    const [currentAirport, setCurrentAirport] = useState(null);
    const [airports, setAirports] = useState([]);
    const [settings, setSettings] = useState([]);
    const [cookies, setCookie] = useCookies(['isAdmin']);
    const handleClose = () => setShow(false);
    const history = createHashHistory();
    const handleShow = (airport) => {
        if (!airport) {
            var teams = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(a => { return { name: "Team" + a, index: a } });
            airport = {
                name: "Alpha" + (airports.length + 1),
                teams: teams,
                index: (airports.length + 1)
            }
        } else {
            airport = {
                id: airport.id,
                name: airport.name,
                teams: airport.teams.map(a => { return { id: a.id, name: a.name } })
            }
        }
        console.log(airport);
        setCurrentAirport(airport);
        setShow(true);
    }
    const save = () => {
        if (currentAirport.id) {
            var command = {
                id: currentAirport.id,
                name: currentAirport.name,
                teams: currentAirport.teams.map(a => { return { id: a.id, name: a.name } })
            }
            request.put("api/airports", command).then(response => {
                LoadAirports();
                setShow(false);
            })
        } else {
            var command = {
                name: currentAirport.name,
                index: currentAirport.index,
                teams: currentAirport.teams.map(a => { return { name: a.name, index: a.index } })
            }
            request.post("api/airports", command).then(response => {
                LoadAirports();
                setShow(false);
            })
        }
    }
    const saveSettings = () => {

        request.put("api/settings", settings).then(response => {
            LoadSettings();
        })
    }
    const reset = () => {
        request.put("api/reset-game").then(response => {
            LoadAirports();
        })
    }
    const airportEnableOrDisable = (airport, event) => {
        console.log(event.target.value);
        var url = "api/airports/" + airport.id + "/" + (event.target.checked ? "enable" : "disable");
        request.put(url).then(response => {
            LoadAirports();
        })
    }
    const teamNameChanged = (index, event) => {
        currentAirport.teams[index].name = event.target.value;
        setCurrentAirport(currentAirport);
    }
    const airportNameChanged = (event) => {
        currentAirport.name = event.target.value;
        setCurrentAirport(currentAirport);
    }
    const settingsChanged = (event) => {
        settings[event.target.name] = event.target.value;
        setSettings(settings);
    }
    const goDashboardAsTeamMember = (airport, team) => {
        setCookie('state', { clientId: cookies.state.clientId, currentGameIdentify: cookies.state.currentGameIdentify, teamId: team.id, teamName: team.name, airportId: airport.id, airportName: airport.name }, { path: '/' });
        history.push('game');
    }
    const joinTeam=(team, airport)=> {
        var command = {
          "clientId": cookies.state.clientId,
          "teamId": team.id,
          "airportId": airport.id,
          "memberName": cookies.state.clientId
        };
        request.post("api/airports/join", command).then(response => {
          setCookie('state', { clientId: cookies.state.clientId, currentGameIdentify: cookies.state.currentGameIdentify, teamId: team.id, teamName: team.name, airportId: airport.id, airportName: airport.name }, { path: '/' });
          LoadAirports();
        })
      }
    function LoadAirports() {
        request.get("api/airports").then(response => {
            setAirports(response.data);
        })
    }
    function LoadSettings() {
        request.get("api/settings").then(response => {
            setSettings(response.data);
        })
    }
    function sum(arary) {
        var reuslt = 0;
        arary.forEach(a => reuslt += a);
        return reuslt;
    }
    useEffect(() => {
        LoadSettings();
        LoadAirports();
        setCookie("isAdmin", true)
    }, [])
    return (
        <Container>

            <Header updateGame={updateGame} isAdminPage={true} reload={() => LoadAirports()} />
            <Row className="mt-3">
                <Col className="border-bottom border-start border-end border-1 p-0 m-0">
                    <Tabs defaultActiveKey="airports">
                        <Tab eventKey="airports" title="Airports">
                            <Container >
                                <Row className="align-items-center my-2">
                                    <Col md={2}>
                                        {/* <h2 className="my-2">Airports</h2> */}
                                    </Col>
                                    <Col className="py-2 d-flex justify-content-end">
                                        <Button variant="primary" className="mx-2" onClick={() => handleShow(null)}>
                                            <BsFillPlusCircleFill />   Create
                                        </Button>
                                        <Button variant="primary" className="mx-2" onClick={reset}>
                                            <BsArrowClockwise />  Reset
                                        </Button></Col>
                                </Row>
                                {airports.length > 0 &&
                                    <Accordion defaultActiveKey={airports[0].id}>
                                        {airports.sort((a, b) => a.index > b.index ? 1 : -1).map(airport =>
                                            <Card key={airport.id}>
                                                <Card.Header>
                                                    <Row>
                                                        <Col md={6}>
                                                            <strong className="px-2 border-end">{airport.name}</strong>
                                                            <small className="px-2 ">{airport.teams.length} Teams</small>
                                                            <small className="px-2 border-start">{airport.teams.filter(a => a.members.length > 0).length} Active Teams</small>
                                                            <small className="px-2 border-start">{sum(airport.teams.map(a => a.members.length))} Members</small>
                                                        </Col>
                                                        <Col md={6} className="d-flex justify-content-end">
                                                            <Form.Check
                                                                className="mt-1"
                                                                inline
                                                                checked={airport.isEnable}
                                                                onChange={(e) => airportEnableOrDisable(airport, e)}
                                                                type="switch"
                                                                id="custom-switch"
                                                            />
                                                            <Button variant="primary" size="sm" className="me-3" onClick={() => handleShow(airport)}>
                                                                <BsPencilSquare />
                                                            </Button>
                                                            <CustomToggle eventKey={airport.id}>
                                                                <BsChevronDown />
                                                            </CustomToggle>

                                                        </Col></Row>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={airport.id} >
                                                    <Card.Body>
                                                        <Row>
                                                            {airport.teams.sort((a, b) => a.index > b.index ? 1 : -1).map(team =>
                                                                <Col md={3} key={team.id} className="text-center">
                                                                    <div className="p-2 me-1 mb-2 border border-1 rounded-pill">
                                                                        {team.members.length > 0 ?
                                                                            <Button variant="link" style={{ textDecoration: "none", padding: 0 }} onClick={() => goDashboardAsTeamMember(airport, team)}>
                                                                                {team.members.length > 0 &&
                                                                                    <BsFillCircleFill className="mx-2 text-success" />
                                                                                }
                                                                                <strong className="pe-2 border-end"> {team.name}</strong></Button>
                                                                            :
                                                                            <strong className="pe-2 border-end"> {team.name}</strong>
                                                                        }
                                                                        <small className="ps-2 ">{team.members.length} members</small>
                                                                        {team.members.length == 0 &&
                                                                         <BsFillPlusCircleFill className="ms-2" style={{cursor: 'pointer'}} onClick={()=>joinTeam(team,airport)}/>  
                                                                        }
                                                                    </div>

                                                                </Col>
                                                            )}

                                                        </Row>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>

                                        )}
                                    </Accordion>
                                }
                            </Container>
                        </Tab>
                        <Tab eventKey="settings" title="Settings">
                            {/* <Row >
                  <Col >
                    <h2 className="my-2">Settings</h2>
                  </Col>
  
                </Row> */}
                            <Container>
                                <Form >


                                    <Row>
                                        <Col md={12}>
                                            <h5 className="my-3">Shift Settings:</h5>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Shift Count:</Form.Label>
                                                <Form.Control type="number" name="shiftCount" onChange={settingsChanged} defaultValue={settings.shiftCount} />

                                            </Form.Group></Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Shift Length(Mins):</Form.Label>
                                                <Form.Control type="number" name="shiftLenght" onChange={settingsChanged} defaultValue={settings.shiftLenght} />

                                            </Form.Group></Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Team Target($):</Form.Label>
                                                <Form.Control type="number" name="teamTarget" onChange={settingsChanged} defaultValue={settings.teamTarget} />

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <h5 className="my-3">Game Engine Settings:</h5>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Landing Headway(Seconds):</Form.Label>
                                                <Form.Control type="number" name="landingHeadway" onChange={settingsChanged} defaultValue={settings.landingHeadway} />

                                            </Form.Group></Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Approach(Mins):</Form.Label>
                                                <Form.Control type="number" name="approach" onChange={settingsChanged} defaultValue={settings.approach} />

                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Push Delay(Seconds):</Form.Label>
                                                <Form.Control type="number" name="pushDelay" onChange={settingsChanged} defaultValue={settings.pushDelay} />

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <h5 className="my-3">Aircraft Weight:</h5>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Passenger_Jet:</Form.Label>
                                                <Form.Control type="number" name="passengerJetPercentage" onChange={settingsChanged} defaultValue={settings.passengerJetPercentage} />

                                            </Form.Group></Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Cargo:</Form.Label>
                                                <Form.Control type="number" name="cargoPercentage" onChange={settingsChanged} defaultValue={settings.cargoPercentage} />

                                            </Form.Group></Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Private_Jet:</Form.Label>
                                                <Form.Control type="number" name="privateJetPercentage" onChange={settingsChanged} defaultValue={settings.privateJetPercentage} />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Military:</Form.Label>
                                                <Form.Control type="number" name="militaryPercentage" onChange={settingsChanged} defaultValue={settings.militaryPercentage} />

                                            </Form.Group></Col>
                                        <Col md={4}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Turbo_Prop:</Form.Label>
                                                <Form.Control type="number" name="turboPropPercentage" onChange={settingsChanged} defaultValue={settings.turboPropPercentage} />

                                            </Form.Group></Col>
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Critical Factor(%):</Form.Label>
                                                <Form.Control type="number" name="criticalFactor" onChange={settingsChanged} defaultValue={settings.criticalFactor} />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col> <Button variant="primary" onClick={saveSettings}>Save</Button></Col>
                                    </Row>
                                </Form>
                            </Container>
                        </Tab>

                    </Tabs>
                    <br />
                </Col>
            </Row>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{currentAirport && currentAirport.id ? "Edit" : "Create"} Airport</Modal.Title>
                </Modal.Header>
                <Modal.Body>{currentAirport &&
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Airport Name</Form.Label>
                            <Form.Control type="input" onChange={airportNameChanged} placeholder="airport name" defaultValue={currentAirport.name} />

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Teams</Form.Label>
                            <Row>
                                {
                                    currentAirport.teams.map((a, index) =>
                                        <Col md="4" key={index}>
                                            <Form.Control onChange={(e) => teamNameChanged(index, e)} type="input" defaultValue={a.name} placeholder="team name" className="mb-2" />
                                        </Col>
                                    )
                                }


                            </Row>


                        </Form.Group>


                    </Form>
                }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}


export default Admin;