import { Button, Card, } from "react-bootstrap";
import { useDrag } from "react-dnd";
import { useCookies } from 'react-cookie';


function Aircraft({ runway, aircraft, push, readOnly }) {
    const [cookies, setCookie] = useCookies(['state']);
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "image",
        item: aircraft,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        canDrag: (monitor) => {
            return !readOnly && (aircraft.runway === "" || aircraft.teamId === cookies.state.teamId);
        }
    }));

    return (
        <div
            ref={drag}
            style={{ backgroundColor: "#efefef", border: "1px solid grey", margin: "5px", float: "left" }} >
            <Card style={{ height: runway == "" ? "" : runway == "team" ? "150px" : "170px", backgroundColor: aircraft.isClash && aircraft.isCritical ? "grey" : aircraft.isClash ? "red" : aircraft.isCritical ? "yellow" : !readOnly && runway !== "" && aircraft.teamId !== cookies.state.teamId ? "#efefef" : "", color: aircraft.isClash ? "white" : "", width: '6rem' }}>
                <Card.Subtitle className="text-center pt-1">
                    <span style={{ fontSize: "small" }}  >{aircraft.index}</span>
                </Card.Subtitle>

                <Card.Img variant="top" height={"60px"} src={aircraft.imgUrl} />
                <Card.Subtitle className="text-center  pt-1">
                    <span style={{ fontSize: "small" }}  >{aircraft.name}</span>
                </Card.Subtitle>
                {runway != "team" &&
                    <Card.Subtitle className="text-center  pt-1">
                        <span style={{ fontSize: "small" }}  >{aircraft.teamName}</span>
                    </Card.Subtitle>
                }
                <Card.Subtitle className="text-center py-1">
                    <span style={{ fontSize: "small" }}>{new Date(aircraft.approach).toTimeString().substring(0, 8)}</span>
                </Card.Subtitle>
                <Card.Subtitle>
                </Card.Subtitle>
                {!readOnly && runway !== "" && aircraft.teamId === cookies.state.teamId &&
                    <Button className="mb-1" variant="primary" size="sm" onClick={() => push(aircraft.id)} disabled={aircraft.isPushed}>{aircraft.isPushed ? "Pushed" : "Push"}</Button>}
                {readOnly && aircraft.isPushed &&
                    <Button className="mb-1" variant="primary" size="sm" disabled={true}>Pushed</Button>}

            </Card>
        </div>
    )
}

export default Aircraft;