import { useDrop } from "react-dnd";
import Aircraft from "./Aircraft";
function AircraftContainer({ runway, aircrafts, updateBlocks, push,readOnly }) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "image",
    drop: (item) => {
      if (item.runway !== runway &&
        ((item.name !== "Helicopter" && runway !== "helipad") ||
          (item.name === "Helicopter" && runway === "helipad"))) {
        updateBlocks(item.id, runway);
      }
    },
    collect: (monitor) => {
      return ({
        isOver: monitor.getItem()?.runway !== runway &&
          ((monitor.getItem()?.name !== "Helicopter" && runway !== "helipad") ||
            (monitor.getItem()?.name === "Helicopter" && runway === "helipad"))
          && !!monitor.isOver(),
      })
    },
  }));
  return (
    <div
      ref={drop}
      style={{ minHeight: runway == "team" ? "160px" : "180px", marginTop: "10px", width: "100%", display: "inline-block", backgroundColor: !isOver ? "#efefef" : "green" }}>
      {aircrafts?.map(a => <Aircraft key={a.id} runway={runway} aircraft={a} push={push} readOnly={readOnly} />)}
    </div>
  )
}


export default AircraftContainer;