import { Row, Col, Button, } from "react-bootstrap";
import { useContext } from 'react';
import { useCookies } from "react-cookie";
import GameContext from "./GameContext";
import { createHashHistory } from 'history';
import { BsFillCircleFill, BsBrightnessAltHigh, BsSkipStartCircle, BsFillPersonLinesFill, BsArrowClockwise, BsStopCircle, BsArrowRightCircle, BsPeople, BsDisplayFill } from "react-icons/bs";
import request from "./Api";

function Header({ updateGame, reload, isAdminPage = false }) {
  const game = useContext(GameContext);
  const [cookies, setCookie] = useCookies(['state', "isAdmin"]);
  const history = createHashHistory();
  const activeGame = () => {

    request.put("api/active-game").then(response => {
      updateGame();
    })
  }
  const startGame = () => {
    request.put("api/start-shift").then(response => {
      updateGame();
    })
  }
  const clearGame = () => {
    request.put("api/clear-game").then(response => {
      updateGame();
    })
  }
  const stopGame = () => {
    request.put("api/stop-game").then(response => {
      updateGame();
      reload();
    })
  }
  return (
    <>
      {game &&
        <Row className="pt-3">
          <Col md={4}>
            <img src="./game-logo.png" className="img-fluid" alt="logo" />
          </Col>
          <Col>
            <Row className="align-items-center">
              <Col md={isAdminPage ? 4 : 12} className="my-3">
                <strong>Game Status:</strong> <small className={game.status == "Active" ? "text-success" : game.status == "Start" ? "text-primary" : game.status == "Deactive" ? "text-muted  text-opacity-25" : ""}><BsFillCircleFill /> {game.status}</small>

                {!isAdminPage && cookies.isAdmin &&
                  <Button variant="primary" size="sm" onClick={() => history.push('/admin')} className="mx-2 float-end" >
                    <BsFillPersonLinesFill />
                    Back To Admin
                  </Button>
                }
              </Col>

              {isAdminPage &&
                <Col md={8} className="d-flex justify-content-end">
                  {
                    game.status === "Deactive" &&
                    <Button variant="success" className="mx-2" onClick={activeGame}>
                      <BsBrightnessAltHigh />
                      Active Game
                    </Button>
                  }
                  {
                    game.status === "Active" &&
                    <Button variant="primary" className="mx-2" onClick={startGame}>
                      <BsSkipStartCircle />
                      Start Game
                    </Button>
                  }
                  {
                    game.status === "Start" &&
                    <>
                      {game.shiftCount !== game.settings.shiftCount &&
                        <Button variant="primary" className="mx-2" onClick={startGame}>
                          <BsArrowRightCircle />
                          Next Shift
                        </Button>
                      }


                      <Button variant="warning" className="mx-2" onClick={clearGame}>
                        <BsArrowClockwise />
                        Clear Game
                      </Button>
                      <Button variant="danger" className="mx-2" onClick={stopGame}>
                        <BsStopCircle />

                        Stop Game
                      </Button>
                      {game.shiftCount >0 &&
                      <Button variant="primary" className="mx-2" onClick={() => history.push("result")}>
                        <BsArrowRightCircle />
                        Game Result
                      </Button>
                      }
                    </>
                  }
                </Col>
              }
              <Col md={12} className="my-3">

                <strong> Active Airports:</strong> <small>{game.activeAirportsCount}</small>
                <strong className="ms-5"> Active Teams:</strong> <small>{game.activeTeamsCount}</small>
                <strong className="ms-5"> Active Members:</strong> <small>{game.activeMembersCount}</small>
                {isAdminPage && cookies.isAdmin &&
                  <Button variant="primary" className="mx-2 float-end" size="sm" onClick={() => history.push("/")}>
                    <BsPeople />
                    Go To Player
                  </Button>
                }

              </Col>
              <Col md={12} className="my-3">
                <strong> Shift Count:</strong> <small>{game.settings.shiftCount}</small>
                <strong className="ms-5"> Shift Length:</strong> <small>{game.settings.shiftLenght} Mins</small>
                <strong className="ms-5"> Current Shift:</strong> <small>{game.shiftCount}</small>
                {isAdminPage && cookies.isAdmin &&
                  <Button variant="primary" className="mx-2 float-end" size="sm" onClick={() => history.push("game")}>
                    <BsDisplayFill />
                    Go To Dashboard
                  </Button>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      }
    </>


  );
}


export default Header;