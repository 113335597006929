import { Container, Row, Col, Table, ToastContainer, Toast, Button, Form } from "react-bootstrap";
import { useState, useEffect, useContext } from 'react'
import GameContext from "./GameContext";
import { io } from "socket.io-client";
import { useCookies } from 'react-cookie';
import { BsFillCircleFill } from "react-icons/bs";
import AircraftContainer from "./AircraftContainer";
import Aircraft from "./Aircraft";
import { createHashHistory } from 'history';
import { BsFillPersonLinesFill } from "react-icons/bs"
import request from "./Api";
require('dotenv').config();
function TeamScore({ airportName, shiftName }) {
    const [gameData, setGameData] = useState({});
    const IfNullReturn0 = function (value) {
        if (value) return value;
        return 0;
    }
    useEffect(() => {
        request.get("api/" + airportName + "/" + shiftName).then(function (resp) {
            setGameData(resp.data);
        });
    }, [airportName, shiftName])
    return (
            <Table bordered>
                <tbody>
                    <tr style={{ height: "150px" }}>
                        <td style={{ width: "50px", backgroundColor: "#87ceeb" }}>
                            <div className="vertical-text-result-page">STACK</div>
                        </td>
                        <td style={{ verticalAlign: "bottom", width:"110px" }} className="text-center">

                            <span><strong> Landed:</strong> <small>{IfNullReturn0(gameData.airLanded)}</small></span>
                            <br />
                            <span><strong> Reroute:</strong> <small>{IfNullReturn0(gameData.airReroute)}</small></span>
                        </td>
                        <td>
                            {gameData.aircrafts?.filter(a => a.runway === "").map(a => <Aircraft runway={""} key={a.id} aircraft={a} readOnly={true} />)}
                        </td>
                    </tr>

                    <tr style={{ height: "150px" }}>
                        <td style={{ width: "50px", backgroundColor: "#999999" }}>
                            <div className="vertical-text-result-page">AUXILIARY</div>
                        </td>
                        <td style={{ verticalAlign: "bottom" }} className="text-center">

                            <span><strong> Landed:</strong> <small>{IfNullReturn0(gameData.auxLanded)}</small></span>
                            <br />
                            <span><strong> Reroute:</strong> <small>{IfNullReturn0(gameData.auxReroute)}</small></span>
                            <br />
                            {gameData.auxLast &&
                                <span><strong> Last:</strong> <small>{new Date(gameData.auxLast).toTimeString().substring(0, 8)}</small></span>}
                        </td>

                        <td>
                            <AircraftContainer runway="aux" aircrafts={gameData.aircrafts?.filter(a => a.runway === "aux")} readOnly={true}  />
                        </td>
                    </tr>
                    <tr style={{ height: "150px" }}>
                        <td style={{ width: "50px", backgroundColor: "#d9d9d9" }}>
                            <div className="vertical-text-result-page">HELIPAD</div>
                        </td>
                        <td style={{ verticalAlign: "bottom" }} className="text-center">

                            <span><strong> Landed:</strong> <small>{IfNullReturn0(gameData.helipadLanded)}</small></span>
                            <br />
                            <span><strong> Reroute:</strong> <small>{IfNullReturn0(gameData.helipadReroute)}</small></span>
                            <br />
                            {gameData.helipadLast &&
                                <span><strong> Last:</strong> <small>{new Date(gameData.helipadLast).toTimeString().substring(0, 8)}</small></span>}
                        </td>

                        <td>
                            <AircraftContainer runway="helipad" aircrafts={gameData.aircrafts?.filter(a => a.runway === "helipad")} readOnly={true}  />
                        </td>
                    </tr>
                    {gameData.teams && gameData.teams.map(a =>
                        <tr style={{ height: "150px" }} key={a.teamName}>
                            <td style={{ width: "50px", backgroundColor: "#ff743d" }}>
                                <div className="vertical-text-result-page">{a.teamName}</div>
                            </td>
                            <td  className="text-center" style={{ verticalAlign: "bottom" }}>

                                <span><strong> Landed:</strong> <small>{IfNullReturn0(a.teamLanded)}</small></span>
                                <br />
                                <span><strong> Reroute:</strong> <small>{IfNullReturn0(a.teamReroute)}</small></span>

                            </td>
                            <td>
                                <AircraftContainer runway="team" aircrafts={a.aircrafts?.filter(a => a.runway === "team")} readOnly={true} />
                            </td>
                        </tr>
                    )}

                </tbody>
            </Table>
    )
}


export default TeamScore;