import { Row, Col, Container, Table, Tabs, Tab, Button } from "react-bootstrap";
import { useEffect, useState } from 'react';

function StatsTable({ heading, subheading, containerId, stats}) {
    //const [airportStatistic, setAirportStatistic] = useState([]);
    //const [selectedAirport, setAirport] = useState(null);
    //const [airportShiftStatistic, setAirportShiftStatistic] = useState([]);
    //const [combinedShiftStatistic, setCombinedShiftStatistic] = useState([]);

    //const history = createHashHistory();

    return (
    <>
        <h4 className='py-4'> { heading } // Team revenue over shifts</h4>
        <div id={containerId} style={{ minWidth: "400px", height: "400px" }}></div>
        <h4 className='py-4'> { subheading } // % difference from shift 1 vs shift 2 vs shift 3</h4>
        <Table striped bordered hover className='text-center'>
            <thead>
                <tr>
                    <th width="80px"></th>
                    <th>Revenue</th>
                    <th>Landings</th>
                    <th>Clashes</th>
                    <th>Opportunities</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td>Money from landing aircraft, less any penalities</td>
                    <td>Aircraft landed, less 'forced' aircraft</td>
                    <td>Aircraft needed to be rerouted due to insufficient time between landings</td>
                    <td>Aircraft grabbed from the Stack</td>
                </tr>
                {stats.map(statistic =>
                <tr key={statistic.name}>
                    <td>{statistic.name}</td>
                    <td>{statistic.revenue + "%"}</td>
                    <td>{statistic.landingSubtractClash + "%"}</td>
                    <td>{statistic.clash + "%"}</td>
                    <td>{statistic.grabbed + "%"}</td>
                </tr>
                )}
            </tbody>
        </Table>
    </>
    );

}

export default StatsTable;
